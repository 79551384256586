.text-bs-gray {
  color: var(--bs-gray-600);
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.dark-mode-theme .text-theme-color {
  color: #ffffff;
}
.light-mode-theme .text-theme-color {
  color: #99a1b7;
}
.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* .rdt_TableCell div:first-child {
  white-space: initial !important;
} */

.price-inputbox {
  color: inherit;
  font-size: 13px;
  font-weight: 400;
  color: rgb(51, 51, 51) !important;
  background-color: #f9f9f9;
  border: 0;
  width: 100%;
  padding: 2px 8px;
  min-height: 38px;
}

.rdt_TableCol_Sortable,
.rdt_TableCol_Sortable:hover,
.rdt_TableCol_Sortable span,
.rdt_TableCol_Sortable span:hover {
  opacity: 1 !important;
}
.query-form-pop-up {
  height: 150px;
  word-break: break-all;
  overflow-y: scroll;
  word-spacing: 2px;
}
.password-toggle-icon {
  float: right;
  margin-top: -32px;
  margin-right: 11px;
  cursor: pointer;
}

.password-toggle-icon-auth {
  float: right;
  margin-top: -32px;
  margin-right: 35px;
  cursor: pointer;
}
/* .rdt_TableCol span {
  align-self: flex-end;
} */

/* .dashboardTable table {
  border-collapse: collapse;
}
td {
  padding: 0;
}
.dashboardTable tr {
  border: 2px dashed var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  border-bottom: 2px;
} */

.fixed-width-childs-100px button,
.fixed-width-childs-100px select {
  width: 105px;
}

.filter-dropDown > .css-13cymwt-control {
  background-color: #f9f9f9;
  border-color: #f9f9f9;
}

.justify-content-center {
  justify-content: center;
}

.text-link-hover:hover {
  color: #ffad72 !important;
}

.input-prepand-text {
  background-color: var(--bs-gray-100);
  border-color: var(--bs-gray-100);
  color: var(--bs-gray-700);
}

.z-index-5 {
  z-index: 5;
}

.z-index-4 {
  z-index: 4;
}

.z-index-3 {
  z-index: 3;
}

.errorColor {
  color: red;
}

.asyncSelectBox.filter-dropDown div:hover {
  border-color: #000;
  box-shadow: none;
}

.react-datepicker__close-icon::after {
  background-color: transparent !important;
  color: rgb(204, 204, 204) !important;
  padding: 0 !important;
  height: 30px !important;
  width: 30px !important;
  font-size: 20px !important;
}

.react-datepicker__close-icon::after:hover {
  color: rgb(153, 153, 153) !important;
}

.react-datepicker__input-container input {
  min-height: auto !important;
}

.form-select-type {
  background-color: var(--bs-gray-100);
  border-color: var(--bs-gray-100);
  color: var(--bs-gray-700);
  transition: color 0.2s ease;
  padding: 0.775rem 1rem 0.775rem 1rem;
  display: block;
  font-weight: 600;
}

/* .dark-mode-theme .filters-select *,
.dark-mode-theme .asyncSelectBox.filter-dropDown * {
  background-color: var(--bs-gray-100) !important;
  border-color: var(--bs-gray-100) !important;
  color: var(----bs-gray-800) !important;
} */
/* .dark-mode-theme .filters-select *::placeholder,
.dark-mode-theme .asyncSelectBox.filter-dropDown *::placeholder,
.dark-mode-theme .react-select__placeholder,
.dark-mode-theme .react-select__single-value {
  color: rgb(146, 146, 159);
} */

.light-mode-theme .linkIcon i:hover {
  color: #ffad72;
}

.dark-mode-theme .linkIcon i:hover {
  color: var(--bs-gray-700);
}

/* menu icon color change */
.aside-menu .menu .menu-item .menu-link .menu-icon,
.aside-menu .menu .menu-item .menu-link .menu-icon .svg-icon,
.aside-menu .menu .menu-item .menu-link .menu-icon i {
  color: #b6b9c8;
}

#kt_content_container header {
  margin-left: 0;
  padding-left: 16px;
}

/* for icons */
/* .sortIcon::before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='100' cy='50' r='40' stroke='black' stroke-width='2' fill='red'/%3E%3Cpolyline points='20,20 40,25 60,40 80,120 120,140 200,180' style='fill:none;stroke:black;stroke-width:3'/%3E%3C/svg%3E ");
  width: 20px;
  height: 20px;
} */

.sortIcon::before {
  width: 20px;
  height: 20px;
}

/* .desc.__rdt_custom_sort_icon__ .sortIcon::before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='100' cy='50' r='40' stroke='black' stroke-width='2' fill='red'/%3E%3Cpolyline points='20,20 40,25 60,40 80,120 120,140 200,180' style='fill:none;stroke:black;stroke-width:3'/%3E%3C/svg%3E ");
} */
/* .rdt_TableCol_Sortable span:last-child {
  display: none;
}
.rdt_TableCol_Sortable:not(.rdt_sorted):after {
  content: url('data:image/svg+xml,%3C!-- Icon for no sorting --%3E');
}

.rdt_TableCol_Sortable.rdt_asc:after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='100' cy='50' r='40' stroke='black' stroke-width='2' fill='red'/%3E%3Cpolyline points='20,20 40,25 60,40 80,120 120,140 200,180' style='fill:none;stroke:black;stroke-width:3'/%3E%3C/svg%3E ");
}

.rdt_TableCol_Sortable.rdt_desc:after {
  content: 'u';
} */

.modal-header {
  justify-content: flex-end !important;
}

.min-h-20px {
  min-height: 20px;
}

.phoneInputButton:hover,
.phoneInputButton:active {
  background-color: #1b1b29 !important;
}

.phoneInputButton {
  background-color: #1b1b29 !important;
  color: rgb(226, 59, 59);
}
.phoneInputDropdown,
.phoneInputSerch {
  background-color: #1b1b29 !important;
}
.phoneInputDropdown > li:hover {
  background-color: #2b2b40 !important;
}

.price-inputbox-light {
  color: inherit;
  font-size: 13px;
  font-weight: 400;
  color: rgb(51, 51, 51) !important;
  background-color: #f9f9f9;
  border: 0;
  width: 100%;
  padding: 2px 8px;
  min-height: 38px;
}
.price-inputbox-dark {
  color: inherit;
  font-size: 13px;
  font-weight: 400;
  color: white !important;
  background-color: #f9f9f9;
  border: 0;
  width: 100%;
  padding: 2px 8px;
  min-height: 38px;
}

.pac-container{
  z-index: 100000 !important;
}

.link-style {
  color: #007bff; 
  text-decoration: none; 
}

.link-style:hover {
  text-decoration: underline; 
}
.table-striped thead {
  border: 1px solid black !important;
  font-weight: bold !important;
 
}
.table-striped th {
  padding-left: 5px !important;
  text-align: center;
  vertical-align: middle;
  width: 33.33% !important; 
  font-size: 16px !important;
}
.tableBody ul li{
  font-size: 16px !important;
}
.tableBody td{
  font-size: 16px !important;
}
.tableBody{
  border: 1px solid black !important;
}
.tableBody tr td{
  padding-left: 5px !important;
}

@media only screen and (max-width: 767px) {
  .table-striped{
    min-width: 800px;
    overflow-x: auto;
    
  }
}
.link-padding {
  margin: 0 5px;
}


.modal-custom {
  max-width: 75%;
}

@media (max-width: 576px) {
  .modal-custom {
    max-width: 100%;
  }
}
