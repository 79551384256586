// React vendors
@import '~socicon/css/socicon.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import '~prism-themes/themes/prism-shades-of-purple.css';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '~animate.css/animate.css';
@import '~nouislider/dist/nouislider.css';
@import '~flatpickr/dist/flatpickr.css';

#root {
  display: contents;
}
.dashboard-list {
  height: 73vh !important;
  overflow: scroll !important;
}
.relative {
  position: relative;
}
.sticky {
  top: 0;
  position: sticky;
  // background: white !important;
  z-index: 9;
}

.MuiTextField-root,
.css-10o2lyd-MuiStack-root > .MuiPickersTextField-root {
  min-width: auto !important;
  width: auto !important;
}

.MuiStack-root {
  padding: unset !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 12px !important;
}

.MuiPickersFadeTransitionGroup-root .MuiDateCalendar-viewTransitionContainer {
  font-size: 15px !important;
}

.MuiDayCalendar-root {
  font-size: 20px !important;
}

.ant-picker-dropdown-placement-bottomLeft {
  z-index: 100000 !important;
}

.ant-picker-dropdown {
  z-index: 100000 !important;
}

.ant-picker-outlined {
  min-height: 42px !important;
  height: 42px !important;
}

.date-width {
  .react-datepicker-wrapper {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .checkbox-custom-width {
    width: 2.5rem !important;
  }
}
