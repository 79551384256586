.table-row-hover-change{
    outline-color:rgba(0,0,0,.12) !important;
    border-bottom-color:rgba(0,0,0,.12) !important ;
}
.table-row-hover-change-light{
    outline-color:initial !important;
    border-bottom-color:initial !important ;
}
.new-input-checkbox{
    border: 1px solid #4b4b50 !important;
}
.view-more-btn{
    border-radius: 50px !important;
    padding: 8px 13px;
    background-color: #848484 !important;
    color: white !important;
}
.display-flex-rows{
  flex-direction: row !important;
  overflow-x: auto !important;

}
@media screen and (max-width: 600px) {
  .datatable-header {
    flex-direction: column;
    gap: 5px;
    align-items: flex-start !important;
  }
}
.filters-select .css-13cymwt-control{
  border-color: #f9f9f9;
  background-color: #f9f9f9;
}
.filters-register-select .css-13cymwt-control{
  background-color: #f9f9f9;
}
